
import Vue from "vue"
import socials from "@/const/sharing.json"
import type { FullSession } from "@/types/api/program"

export default Vue.extend({
  props: {
    session: { type: Object as VuePropType<FullSession>, required: true },
    isAsideOpen: { type: Boolean, default: false },
    noActions: { type: Boolean, default: false },
  },

  data: () => ({
    isShareVisible: false,
    socials,
  }),

  computed: {
    url (): string { return process.browser ? window.location.href : "" },
  },

  methods: {
    async vote (value: 1 | -1) {
      const { session } = this
      if (session.voted) return
      if (!this.$auth.loggedIn) return this.$nuxt.$emit("open-login")

      const updateSession = (updates: Record<string, any>) =>
        this.$emit("update:session", { ...session, ...updates })

      updateSession({ votes: session.votes + value, voted: true })
      const [, err] =
        await this.$api.program.addSessionVote(session.alias, value)
      if (err) {
        console.error(err)
        updateSession({})
      }
    },
  },
})
